/* eslint-disable jsx-a11y/no-static-element-interactions */
import BookmarkButton from "@/bookmarks/BookmarkButton"
import ShareContentUsageButton from "@/content-usage/buttons/share/ShareContentUsageButton"
import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import {
  useContentUsageDrawerContext,
  useSetContentUsageDrawerParentEntity,
} from "@/content-usage/drawer/ContentUsageDrawerContext"
import ContentUsageQuizSubmissionsDrawerContent from "@/content-usage/drawer/ContentUsageQuizSubmissionsDrawerContent"
import InlineContentDrawerFooter, {
  CONTENT_DRAWER_FOOTER_HEIGHT,
  CONTENT_DRAWER_FOOTER_USAGES_MD_HEIGHT,
  CONTENT_DRAWER_FOOTER_USAGES_XS_HEIGHT,
} from "@/content-usage/drawer/footer/InlineContentDrawerFooter"
import InlineContentDrawerHeaderOverflow from "@/content-usage/drawer/InlineContentDrawerHeaderOverflow"
import ContentLabelSelectorDropdown from "@/content-usage/drawer/label/ContentLabelSelectorDropdown"
import ContentLabelUtils from "@/content-usage/drawer/label/ContentLabelUtils"
import QuizEditorDrawerContent from "@/content-usage/drawer/quizzes/editor/QuizEditorDrawerContent"
import useQuizEditorDrawer from "@/content-usage/drawer/quizzes/editor/useQuizEditorDrawer"
import QuizContentBanner from "@/content-usage/drawer/quizzes/QuizContentBanner"
import QuizEditorBanner from "@/content-usage/drawer/quizzes/QuizEditorBanner"
import QuizSubmissionEditor from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditor"
import UpdateContentDueDateNotificationModal from "@/content-usage/drawer/settings/UpdateContentDueDateNotificationModal"
import ContentUsageAssignmentSubmitBanner from "@/content-usage/drawer/submissions/ContentUsageAssignmentSubmitBanner"
import ConnectSlackCurriculumItemBanner from "@/content-usage/drawer/system-tasks/ConnectSlackCurriculumItemBanner"
import CurriculumConnectSlackOnboardingDrawer from "@/content-usage/drawer/system-tasks/CurriculumConnectSlackOnboardingDrawer"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import useInlineContentDrawerContentLabel from "@/content-usage/drawer/useInlineContentDrawerContentLabel"
import useTrackContentView from "@/content-usage/drawer/useTrackContentView"
import { InlineContentDrawerTemplateFragment$key } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import { InlineContentDrawerTemplateQuery } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateQuery.graphql"
import { InlineContentDrawerTemplate_ModuleContentUsageFragment$key } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplate_ModuleContentUsageFragment.graphql"
import { InlineContentDrawerTemplate_ModuleContentUsageQuery } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplate_ModuleContentUsageQuery.graphql"
import {
  InlineContentDrawerTemplate_UpdateContentUsageMutation,
  UpdateContentUsageInput,
} from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplate_UpdateContentUsageMutation.graphql"
import { EditContentInput } from "@/content-usage/drawer/__generated__/InlineContentDrawerTitleFormMutation.graphql"
import { useGenerateContentWithAI } from "@/content/ai/GenerateContentWithAIProvider"
import GenerateContentWithAIStatusPopup from "@/content/ai/GenerateContentWithAIStatusPopup"
import ContentFooterSection from "@/content/detail/sections/ContentFooterSection"
import InlineContentDescriptionSection from "@/content/inline/InlineContentDescriptionSection"
import InlineContentHeroAndProperties from "@/content/inline/InlineContentHeroAndProperties"
import InlineContentTitleSection from "@/content/inline/InlineContentTitleSection"
import {
  CONTENT_TEMPLATES,
  getContentTemplateDefaultCoverPhoto,
  getContentTemplateDefaultEmbedKind,
  getContentTemplateDefaultRichDescription,
  getContentTemplateDefaultThumbnail,
} from "@/content/util/contentTemplates"
import ContentUtils from "@/content/util/contentUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useProductsAdminLearnMode } from "@/core/context/ProductsAdminLearnModeContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import FormStore, { ObservableState, useFormStore } from "@/core/form/store/FormStore"
import NotFoundPageContent from "@/core/route/component/not-found/NotFoundPageContent"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import LearnModeSwitch from "@/experience/components/LearnModeSwitch"
import MemberGroupEventsList from "@/occurrence/calendar/modal/list/MemberGroupEventsList"
import CompleteProfileCurriculumItemForm from "@/product/course/curriculum/section/drawer/items/profile/CompleteProfileCurriculumItemForm"
import RelayEnvironment from "@/relay/RelayEnvironment"
import Relay from "@/relay/relayUtils"
import {
  cleanWebFormQuestionsInput,
  CreateWebFormRevisionInput,
  getWebFormRevisionInput,
} from "@/web-form/utils/webFormEditorUtils"
import { WebFormSubmissionsUtils } from "@/web-form/utils/webFormSubmissionsUtils"
import styleIf from "@assets/style/util/styleIf"
import EditorUtils from "@components/editor/EditorUtils"
import { EditorInstance } from "@components/editor/LexicalEditor"
import Form from "@components/form/Form"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoDrawerHeader,
  DiscoEmptyState,
  DiscoIcon,
  DiscoIconButton,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { Divider } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import useDisclosure from "@utils/hook/useDisclosure"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import classnames from "classnames"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { commitLocalUpdate, graphql, useFragment, useLazyLoadQuery } from "react-relay"

export type InlineContentDrawerMode = "add" | "edit" | "read"

export type InlineContentDrawerFormState = Omit<UpdateContentUsageInput, "content"> & {
  content: Omit<EditContentInput, "webFormRevision"> & {
    webFormRevision?: CreateWebFormRevisionInput | null
  }
  moduleReleasedAt?: string | null
  mode: InlineContentDrawerMode
}

export type InlineContentDrawerFormStore = FormStore<
  InlineContentDrawerFormState,
  InlineContentDrawerTemplate_UpdateContentUsageMutation
>

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InlineContentDrawerTemplateProps extends TestIDProps {}

function InlineContentDrawerTemplate(props: InlineContentDrawerTemplateProps) {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const { testid = "InlineContentDrawer" } = props
  const isMobile = useIsMobile()
  const drawer = useContentUsageDrawer()
  const { goToQuestionsEditor } = useQuizEditorDrawer()
  const memberLabel = useLabel("admin_member")

  const [showUpdateDueDateNotificationModal, setShowUpdateDueDateNotificationModal] =
    useState(false)
  const createWebFormConfirmationModal = useDisclosure(false)
  const webFormQuestionsConfirmationModal = useDisclosure(false)

  const {
    drawerContentUsageId: contentUsageId,
    drawerModuleContentUsageId: moduleContentUsageId,
    brIds,
  } = drawer.params
  const { filterContentLabelId } = useQueryParams<{ filterContentLabelId?: string }>()

  // If creating a content usage we may need the context of the parent of that module
  const { moduleContentUsage } =
    useLazyLoadQuery<InlineContentDrawerTemplate_ModuleContentUsageQuery>(
      graphql`
        query InlineContentDrawerTemplate_ModuleContentUsageQuery($id: ID!) {
          moduleContentUsage: node(id: $id) {
            ... on ContentUsage {
              id
              ...InlineContentDrawerTemplate_ModuleContentUsageFragment
            }
          }
        }
      `,
      {
        id: moduleContentUsageId || "",
      },
      { fetchPolicy: "store-and-network" }
    )

  const [response, refetch] = Relay.useRefetchableQuery<InlineContentDrawerTemplateQuery>(
    graphql`
      query InlineContentDrawerTemplateQuery($id: ID!) {
        contentUsage: node(id: $id) {
          ... on ContentUsage {
            ...InlineContentDrawerTemplateFragment
          }
        }
      }
    `,
    {
      id: contentUsageId,
    }
  )

  const contentUsage = useFragment<InlineContentDrawerTemplateFragment$key>(
    graphql`
      fragment InlineContentDrawerTemplateFragment on ContentUsage {
        id
        isLocked
        hasPrerequisites
        entity
        entityId
        ordering
        dueAt
        submissionMethod
        submissionVisibility
        submissionFormat {
          text {
            enabled
            required
          }
          file {
            enabled
            required
          }
          link {
            enabled
            required
          }
        }
        showComments
        passPercentage
        content {
          id
          name
          type
          label
          systemTaskKind
          richEditorDescriptionContent
          attachments {
            edges {
              node {
                id
                name
                mediaUrl
              }
            }
          }
          thumbnailUrl
          coverPhoto
          coverVideo
          coverVideoAsset {
            id
          }
          coverVideoDurationSeconds
          showCoverPlayIcon
          usages {
            totalCount
          }
          contentLabelId
          webForm {
            id
            currentRevision {
              id
              ...webFormEditorUtils_getFormStateFromRevisionFragment @relay(mask: false)
            }
          }
          ...InlineContentHeroSectionFragment
          ...InlineContentAttachmentsSectionFragment
          ...InlineContentDescriptionSectionFragment
          ...InlineContentHeroAndProperties_ContentFragment
          ...BookmarkButtonContentFragment
          ...DiscoEditorMentionsFragment
        }
        prerequisites {
          edges {
            node {
              id
            }
          }
        }
        product {
          id
          adminCanLearnMode
          ...LearnModeSwitchFragment
        }
        productApp {
          id
          kind
        }
        module {
          name
          usages {
            edges {
              node {
                id
                ...InlineContentDrawerTemplate_ModuleContentUsageFragment
              }
            }
          }
        }
        contentDashboardBlock {
          id
        }
        confirmationModalContent {
          id
          name
          richEditorDescriptionContent
          ctaText
        }
        ...QuizSubmissionEditorContentUsageFragment
        ...InlineContentDrawerTitleFormFragment
        ...InlineContentDrawerHeaderOverflowFragment
        ...ContentFooterSection_ContentUsageFragment
        ...InlineContentDrawerFooterFragment
        ...usePermissionsFragment
        ...useTrackContentViewFragment
        ...QuizEditorBanner_ContentUsageFragment
        ...ContentUsageAssignmentSubmitBannerFragment
        ...ConnectSlackCurriculumItemBannerFragment
        ...BookmarkButtonContentUsageFragment
        ...ShareContentUsageButtonFragment
      }
    `,
    response.contentUsage
  )

  const moduleUsage =
    useFragment<InlineContentDrawerTemplate_ModuleContentUsageFragment$key>(
      graphql`
        fragment InlineContentDrawerTemplate_ModuleContentUsageFragment on ContentUsage {
          id
          entity
          entityId
          releasedAt
          isReleased
          isLocked
          hasPrerequisites
          content {
            id
            name
            isCurriculumModule
            children {
              totalCount
            }
          }
        }
      `,
      Relay.connectionToArray(contentUsage?.module?.usages)[0] ?? moduleContentUsage
    )

  useTrackContentView(contentUsage)

  // Must provide these as params on creation otherwise we fetch from existing contentUsage
  const contentType = drawer.params.drawerContentType || contentUsage?.content.type
  const contentSystemTaskKind =
    drawer.params.drawerContentSystemTaskKind || contentUsage?.content.systemTaskKind

  const parentEntity = drawer.params.drawerUsageEntity || contentUsage?.entity
  const parentEntityId = drawer.params.drawerUsageEntityId || contentUsage?.entityId

  const parentPermissions = useActiveProductOrOrganizationPermissions()
  const usagePermissions = usePermissions(contentUsage)
  const canManage = contentUsage
    ? usagePermissions.has("content.manage")
    : parentPermissions.has("content.manage")
  const contentLabels = ContentUtils.useContentLabels()

  const { setContentUsageForm, isSidebarCollapsed } = useContentUsageDrawerContext()

  // Can not rely on useContentUsageDrawerContext on first render as
  // drawerParentEntity is not set until the second render
  const isCurriculum = moduleUsage?.entity === "curriculum"
  const isCollection = moduleUsage?.entity === "collection"
  const isPageApp = contentUsage?.productApp?.kind === "content"
  const isWebFormContent = ContentUtils.isWebFormContent(contentType)
  const isAssignment = contentType === "assignment"
  const isConnectSlackTask = contentSystemTaskKind === "connect_slack"
  const isContentDashboardBlock = Boolean(contentUsage?.contentDashboardBlock)
  const prerequisites = contentUsage
    ? Relay.connectionToArray(contentUsage.prerequisites)
    : []

  const formState: InlineContentDrawerFormState = {
    contentUsageId,
    organizationId: activeOrganization.id,
    productId: contentUsage ? contentUsage?.product?.id : activeProduct?.id,
    contentUsageInput: {
      entity: parentEntity,
      entityId: parentEntityId,
      ordering: getDefaultOrdering(),
      dueAt: contentUsage?.dueAt,
      showComments: contentUsage ? contentUsage.showComments ?? false : true,
      passPercentage: contentUsage?.passPercentage,
      prerequisiteIds: moduleUsage?.content.isCurriculumModule
        ? prerequisites.map((prerequisite) => prerequisite.id)
        : null,
      ...getDefaultSubmissionSettings(),
      confirmationModalInput: getDefaultConfirmationModal(),
    },
    contentId: contentUsage?.content?.id,
    contentType,
    contentSystemTaskKind,
    content: {
      name: contentUsage?.content?.name || "",
      richEditorDescriptionContent: getDefaultRichEditorDescription(),
      attachments: Relay.connectionToArray(contentUsage?.content?.attachments),
      coverPhoto: getDefaultContentCoverPhoto(),
      coverVideo: contentUsage?.content?.coverVideo,
      coverVideoDurationSeconds: contentUsage?.content?.coverVideoDurationSeconds,
      thumbnailUrl: getDefaultContentThumbnail(),
      showCoverPlayIcon: contentUsage?.content?.showCoverPlayIcon,
      contentLabelId: getDefaultContentLabelId(),
      coverVideoAssetId: contentUsage?.content?.coverVideoAsset?.id,
      isAiGenerated: false,
      ...getDefaultWebFormRevision(),
    },
    moduleReleasedAt: moduleUsage?.releasedAt,
    mode: getMode(),
    detachFromContent: false,
  }

  const form = useFormStore<
    InlineContentDrawerTemplate_UpdateContentUsageMutation,
    InlineContentDrawerFormState
  >(
    graphql`
      mutation InlineContentDrawerTemplate_UpdateContentUsageMutation(
        $input: UpdateContentUsageInput!
        $contentLabelIds: [ID!]
      ) {
        response: updateContentUsage(input: $input) {
          node {
            id
            contentCoverVideoAssetUsage {
              id
            }
            contentCoverVideoAssetUsagePlays
            contentCoverVideoAssetUsageAverageCompletion
            content {
              id
              type
              label
              systemTaskKind
              contentLabelId
              coverPhotoAsset {
                id
              }
              coverVideoAsset {
                id
              }
              organization {
                contentLabels {
                  edges {
                    node {
                      id
                      label
                      defaultContentTypes
                      updatedAt
                    }
                  }
                }
              }
            }
            module {
              id
              type
              usages {
                edges {
                  node {
                    id
                    ...ContentModuleUtils_RefreshContentModulesFragment
                      @arguments(contentLabelIds: $contentLabelIds)
                  }
                }
              }
              children {
                edges {
                  node {
                    id
                    ...DeleteContentUsageButtonFragment
                  }
                }
              }
            }
            ...InlineContentDrawerTemplateFragment
            ...ContentUsageGridItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      ...formState,
    }
  )

  // If the permissions change (ie. if admin exits learn mode) we need to re-calculate the mode and set that as the initial state
  useEffect(() => {
    form.state.mode = getMode()
    form.resetInitialState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canManage])

  useEffect(() => {
    const isChanged = Boolean(form.isChanged)
    // When the contentId is updated due to detaching the usage we need to refresh the form state
    form.state = {
      ...(formState as ObservableState<InlineContentDrawerFormState>),
      // Maintain the changed state of the form
      ...(form.changedState as Partial<ObservableState<InlineContentDrawerFormState>>),
      content: {
        ...(formState.content as Partial<
          ObservableState<InlineContentDrawerFormState>
        >["content"]),
        ...(form.changedState.content as Partial<
          ObservableState<InlineContentDrawerFormState>
        >["content"]),
      },
      contentUsageInput: {
        ...formState.contentUsageInput,
        ...form.changedState.contentUsageInput,
      },
    }
    // Manually reset form state to avoid the form being marked as changed if it was not
    if (!isChanged) form.resetInitialState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentUsage?.content?.id])

  const contentLabel = useInlineContentDrawerContentLabel(
    form.state.content.contentLabelId ?? "",
    contentUsage
  )

  useEffect(() => {
    setContentUsageForm(form)
  }, [setContentUsageForm, form])

  const { setUnsavedChanges, handleLeave } = useUnsavedChangesModalContext()

  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form?.isChanged, setUnsavedChanges])

  useEffect(() => {
    if (contentUsage && drawer.params.drawerContentType) {
      // Once created usage is loaded remove the query params that were used for creation
      drawer.clearCreationParams()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentUsage, drawer.params])

  /**
   * Set details of the parent entity into the drawer context
   * This is done here so that this query can pass information to the sidebar
   * The sidebar can not be a child of the query as it will suspend the module navigation component
   */
  useSetContentUsageDrawerParentEntity({
    module: moduleUsage
      ? {
          id: moduleUsage.content.id,
          name: moduleUsage.content.name || "",
          parentEntity: moduleUsage.entity,
          parentEntityId: moduleUsage.entityId,
        }
      : null,
    productApp: contentUsage?.productApp || null,
    contentDashboardBlock: contentUsage?.contentDashboardBlock
      ? {
          id: contentUsage.contentDashboardBlock.id,
        }
      : null,
    organization:
      contentUsage?.entity === "organization" ? { id: contentUsage.entityId } : null,
  })

  const {
    coverImageStatus,
    stopGenerating,
    closeStatusPopup,
    showStatusPopup: showGenerationStatus,
    titleStatus,
    descriptionStatus,
    handleDrawerMount,
  } = useGenerateContentWithAI()

  const viewingSubmission = Boolean(
    drawer.params.submissionId && drawer.params.drawerTab === "submissions"
  )

  const hasSidebarContent = Boolean(
    (isCurriculum ||
      isCollection ||
      (canManage && (isPageApp || isContentDashboardBlock)) ||
      contentUsage?.showComments) &&
      !viewingSubmission
  )

  const totalUsages = contentUsage?.content.usages?.totalCount || 0
  const editingContent = Boolean(form.state.mode === "edit" && form.isChanged)
  const addingContent = Boolean(
    form.state.mode === "add" && drawer.params.contentTemplate
  )
  const updatingMultipleUsages = totalUsages > 1 && editingContent
  const hasValidDrawerContent = Boolean(
    contentUsage || (form.state.mode !== "read" && drawer.params.contentTemplate)
  )

  const showFooter = Boolean(
    addingContent || editingContent || isCurriculum || (hasSidebarContent && isMobile)
  )

  const theme = useTheme()
  const classes = useStyles({
    hasSidebarContent,
    showFooter,
    canManage,
    isSidebarCollapsed,
  })

  const usageSubmissions = WebFormSubmissionsUtils.useCount({
    contentUsageId: contentUsage?.id,
    webFormId: form.state.content.webFormRevision?.webFormId,
    // if user is trying to edit an exiting web form, we need to check if submissions exist
    // avoid querying submissions in all other circumstances, since the FieldResolver may throw unauthorized error
    include: form.state.mode === "edit",
  })

  const { isViewerInLearnModeForProduct } = useProductsAdminLearnMode()
  const isInLearnMode = contentUsage?.product
    ? isViewerInLearnModeForProduct(contentUsage.product.id)
    : false

  if (drawer.params.drawerConnectSlackTab === "connect-slack")
    return (
      <div className={classes.container} data-testid={`${testid}.${form.state.mode}`}>
        <CurriculumConnectSlackOnboardingDrawer contentUsageId={contentUsageId} />
      </div>
    )

  if (drawer.params.drawerQuizTab === "questions")
    return (
      <div className={classes.container} data-testid={`${testid}.${form.state.mode}`}>
        {/* show the viewer submission */}
        {renderWebFormSubmission()}
      </div>
    )
  if (drawer.params.drawerQuizTab === "questions-editor")
    return (
      <div className={classes.container} data-testid={`${testid}.${form.state.mode}`}>
        <QuizEditorDrawerContent
          form={form}
          // can't edit web form if there are submissions for this usage
          readOnly={!!usageSubmissions}
          contentLabel={contentLabel}
        />
      </div>
    )
  if (drawer.params.drawerQuizTab?.startsWith("submissions"))
    return contentUsage?.content.webForm?.currentRevision && canManage ? (
      <ContentUsageQuizSubmissionsDrawerContent
        content={contentUsage.content}
        revision={contentUsage.content.webForm.currentRevision}
        contentUsageId={contentUsage.id}
        contentLabel={contentLabel}
        membersOnly={
          contentUsage.product ? !contentUsage.product.adminCanLearnMode : false
        }
      />
    ) : (
      <>
        <DiscoDrawerHeader
          className={classes.drawerHeader}
          classes={{ header: classes.drawerHeaderContent }}
          title={""}
          onClose={drawer.close}
        />
        <NotFoundPageContent />
      </>
    )

  return (
    <div className={classes.container} data-testid={`${testid}.${form.state.mode}`}>
      <DiscoDrawerHeader
        className={classes.drawerHeader}
        classes={{ header: classes.drawerHeaderContent }}
        showViewAsMemberButton={form.state.mode === "edit"}
        testid={testid}
        title={renderTitle()}
        overlineTitle={renderOverlineTitle()}
        onClose={handleClose}
        headerActions={
          <>
            {form.state.mode === "edit" && contentUsage && (
              <>
                <InlineContentDrawerHeaderOverflow
                  contentUsageKey={contentUsage}
                  testid={testid}
                />
                <ShareContentUsageButton contentUsageKey={contentUsage}>
                  {(buttonProps) => (
                    <DiscoIconButton
                      {...buttonProps}
                      tooltip={"Share"}
                      testid={`${testid}.share-button`}
                    >
                      <DiscoIcon icon={"share"} />
                    </DiscoIconButton>
                  )}
                </ShareContentUsageButton>
              </>
            )}
            {/* Learn Mode */}
            {isInLearnMode && contentUsage?.product && (
              <LearnModeSwitch
                testid={"InlineContentDrawer"}
                productKey={contentUsage.product}
              />
            )}
            {contentUsage && (
              <BookmarkButton
                testid={testid}
                contentKey={contentUsage.content}
                contentUsageKey={contentUsage}
                className={classes.bookmarkButton}
                kind={"content_usage"}
                inHeader
              />
            )}
          </>
        }
      />
      <div
        className={classnames(classes.content, {
          [classes.multipleUsagesContent]: updatingMultipleUsages,
        })}
      >
        {renderContent()}
      </div>
      {form && showUpdateDueDateNotificationModal && (
        <UpdateContentDueDateNotificationModal
          form={form}
          onSubmit={handleSubmit}
          onClose={() => setShowUpdateDueDateNotificationModal(false)}
          contentName={contentUsage?.content.name}
        />
      )}
      <DiscoWarningModal
        isOpen={createWebFormConfirmationModal.isOpen}
        variant={"primary"}
        testid={"CreateQuizQuestionWarningModal"}
        icon={"info"}
        modalContentLabel={`Confirm save ${contentLabel}`}
        title={`Save ${contentLabel}`}
        description={`Please note that once a ${memberLabel.singular} starts submitting answers, you won't have the ability to edit questions.`}
        confirmationButtonProps={{
          onClick: handleSubmit,
          shouldDisplaySpinner: form.isSubmitting,
          disabled: form.disabled,
          children: `Ok, Save ${contentLabel}`,
        }}
        cancelButtonText={"Stay here"}
        onClose={createWebFormConfirmationModal.onClose}
      />
      <DiscoWarningModal
        isOpen={webFormQuestionsConfirmationModal.isOpen}
        variant={"primary"}
        testid={"QuizQuestionsConfirmationModal"}
        icon={"info"}
        modalContentLabel={`Create ${contentLabel} questions`}
        title={`Set Up ${contentLabel} Questions`}
        description={`Before you can save this ${contentLabel}, you need to add at least one question.`}
        confirmationButtonProps={{
          onClick: () => {
            webFormQuestionsConfirmationModal.onClose()
            goToQuestionsEditor()
          },
          children: `Set Up Questions`,
        }}
        cancelButtonText={"Stay here"}
        onClose={webFormQuestionsConfirmationModal.onClose}
      />
    </div>
  )

  function getMode() {
    return canManage ? (contentUsage ? "edit" : "add") : "read"
  }

  function renderContent() {
    if (contentUsage?.isLocked && !canManage)
      return (
        <DiscoEmptyState
          testid={"InlineContentDrawerTemplate.isLocked"}
          align={"center"}
          icon={<DiscoIcon icon={"lock"} color={theme.palette.text.primary} />}
          title={"Locked"}
          subtitle={"This content is locked until you complete the prerequisites."}
        />
      )
    // Form should always be rendered but does not wrap tabs as tabs may also contain forms and they can not be nested
    return (
      <>
        <Form
          id={"InlineContentDrawerForm"}
          testid={`${testid}.${form.state.mode}`}
          onSubmit={handleSubmit}
        />
        {(!moduleUsage?.isLocked || canManage) && (
          <ScrollShadowContainer>
            <div className={classes.contentContainer}>{renderTab()}</div>
          </ScrollShadowContainer>
        )}
        {showFooter && (
          <InlineContentDrawerFooter form={form} contentUsageKey={contentUsage} />
        )}
      </>
    )
  }

  function renderWebFormSubmission() {
    const revisionId = contentUsage?.content?.webForm?.currentRevision?.id
    if (!contentUsage?.id || !revisionId) return null
    return (
      <QuizSubmissionEditor
        revisionId={revisionId}
        contentLabel={contentLabel}
        contentUsageKey={contentUsage}
      />
    )
  }

  function getDefaultOrdering() {
    if (contentUsage?.ordering !== undefined) return contentUsage.ordering
    if (isCurriculum) return moduleUsage?.content?.children?.totalCount || 0
    return 0
  }

  function getDefaultContentCoverPhoto() {
    if (contentUsage?.content) return contentUsage.content.coverPhoto
    if (!drawer.params.contentTemplate) return ""
    return getContentTemplateDefaultCoverPhoto(drawer.params.contentTemplate)
  }

  function getDefaultContentThumbnail() {
    if (contentUsage?.content) return contentUsage.content.thumbnailUrl
    if (!drawer.params.contentTemplate) return ""
    return getContentTemplateDefaultThumbnail(drawer.params.contentTemplate)
  }

  function getDefaultRichEditorDescription() {
    const editorContent = EditorUtils.getInitialEditorState(
      contentUsage?.content?.richEditorDescriptionContent
    )
    if (editorContent && !EditorUtils.isEmpty(editorContent)) return editorContent
    if (!drawer.params.contentTemplate)
      return JSON.stringify(EditorUtils.createParagraphs([""]))
    return getContentTemplateDefaultRichDescription(drawer.params.contentTemplate)
  }

  function getDefaultEmbed() {
    // If editor is not empty do not set a default embed
    if (!EditorUtils.isEmpty(contentUsage?.content?.richEditorDescriptionContent))
      return null
    if (!drawer.params.contentTemplate) return null
    return getContentTemplateDefaultEmbedKind(drawer.params.contentTemplate)
  }

  function getDefaultContentLabelId() {
    if (contentUsage?.content.contentLabelId) return contentUsage.content.contentLabelId

    if (drawer.params.contentTemplate) {
      return ContentLabelUtils.getDefaultContentLabelIdByContentType(
        CONTENT_TEMPLATES[drawer.params.contentTemplate].contentType,
        contentLabels
      )
    } else if (drawer.params.drawerContentType) {
      // This is the legacy param (used when contentLibrary FF is off)
      return ContentLabelUtils.getDefaultContentLabelIdByContentType(
        drawer.params.drawerContentType,
        contentLabels
      )
    }
    return null
  }

  function getDefaultConfirmationModal() {
    if (contentUsage?.confirmationModalContent)
      return {
        name: contentUsage.confirmationModalContent.name,
        richEditorDescriptionContent:
          contentUsage.confirmationModalContent.richEditorDescriptionContent,
        ctaText: contentUsage.confirmationModalContent.ctaText,
      }
    return null
  }

  function getDefaultSubmissionSettings() {
    if (contentType !== "assignment") return {}

    if (contentUsage) {
      return {
        submissionMethod: contentUsage?.submissionMethod,
        submissionVisibility: contentUsage?.submissionVisibility,
        submissionFormat: contentUsage?.submissionFormat,
      }
    }

    return ContentUsageUtils.getDefaultSettings(contentType)
  }

  function getDefaultWebFormRevision(): { webFormRevision?: CreateWebFormRevisionInput } {
    if (!isWebFormContent) return {}

    if (contentUsage)
      return {
        webFormRevision:
          getWebFormRevisionInput(
            contentUsage?.content.webForm?.currentRevision,
            contentUsage?.id
          ) || undefined,
      }

    return { webFormRevision: { webFormId: "", questions: [] } }
  }

  function renderTab() {
    if (!hasValidDrawerContent) return <NotFoundPageContent />

    if (contentSystemTaskKind === "add_events_to_calendar") {
      return <MemberGroupEventsList />
    } else if (contentSystemTaskKind === "complete_profile") {
      return <CompleteProfileCurriculumItemForm productId={contentUsage?.product?.id} />
    }

    return (
      <>
        <InlineContentHeroAndProperties
          mode={form.state.mode}
          contentForm={form}
          contentKey={contentUsage?.content}
          contentUsageId={contentUsage?.id}
          contentTemplateKind={drawer.params.contentTemplate}
          isLoading={coverImageStatus === "loading"}
        />
        <InlineContentDescriptionSection
          contentMentionKey={contentUsage?.content}
          {...getInlineContentProps()}
          defaultEmbedKind={getDefaultEmbed()}
          staticSectionClasses={{ root: classes.richEditor }}
          onMount={(editor: EditorInstance) => handleDrawerMount(form, editor, drawer)}
          moduleContentUsageId={moduleUsage?.id}
        />
        {isConnectSlackTask && (
          <ConnectSlackCurriculumItemBanner contentUsageKey={contentUsage} />
        )}
        {isWebFormContent &&
          (form.state.mode === "read" ? (
            contentUsage?.content.webForm?.currentRevision ? (
              <QuizContentBanner
                contentUsageKey={contentUsage}
                revisionId={contentUsage.content.webForm.currentRevision.id}
                contentUsageId={contentUsage.id}
                contentLabel={contentLabel}
              />
            ) : null
          ) : (
            <QuizEditorBanner
              form={form}
              mode={form.state.mode}
              contentUsageKey={contentUsage}
              usageSubmissionsCount={usageSubmissions}
              contentLabel={contentLabel}
            />
          ))}

        {isAssignment && (
          <ContentUsageAssignmentSubmitBanner
            form={form}
            contentUsageKey={contentUsage}
            mode={form.state.mode}
            contentLabel={contentLabel}
          />
        )}
        {contentUsage && (isCollection || isContentDashboardBlock) && (
          <>
            <Divider />
            <ContentFooterSection
              className={classes.contentFooterSection}
              contentUsageKey={contentUsage}
              testid={testid}
              hideCommentButton
            />
          </>
        )}

        {showGenerationStatus && (
          <GenerateContentWithAIStatusPopup
            statuses={[
              {
                name: "Title",
                status: titleStatus,
              },

              {
                name: "Cover Image",
                status: coverImageStatus,
              },
              {
                name: "Description",
                status: descriptionStatus,
              },
            ]}
            onClose={closeStatusPopup}
          />
        )}
      </>
    )
  }

  // Ensures inline components only receive a form or fragment key
  function getInlineContentProps() {
    if (form.state.mode === "read") {
      return { contentKey: contentUsage!.content, contentUsageId: contentUsage!.id }
    }

    return { form }
  }

  function renderTitle() {
    switch (form.state.mode) {
      case "edit":
        return <InlineContentTitleSection form={form} />
      case "add":
        if (!hasValidDrawerContent)
          return <InlineContentTitleSection name={"Content Not Found"} />
        return <InlineContentTitleSection form={form} focusOnMount />
      case "read":
        if (!contentUsage) return <InlineContentTitleSection name={"Content Not Found"} />
        return (
          <InlineContentTitleSection name={contentUsage?.content.name || "Untitled"} />
        )
    }
  }

  function renderOverlineTitle() {
    if (!hasValidDrawerContent) return ""

    if (form.state.mode !== "read")
      return <ContentLabelSelectorDropdown form={form} contentLabel={contentLabel} />
    return (
      <DiscoText
        variant={"body-xs-600-uppercase"}
        color={"primary.main"}
        data-testid={"InlineContentDrawer.OverlineTitle.static"}
      >
        {contentLabel}
      </DiscoText>
    )
  }

  async function handleSubmit() {
    stopGenerating()

    // If editing an assignment due date in a released module show a due date notification modal
    if (
      form.state.mode === "edit" &&
      moduleUsage?.isReleased &&
      !showUpdateDueDateNotificationModal &&
      form.state.contentType === "assignment" &&
      form.changedState.contentUsageInput?.dueAt !== undefined
    ) {
      setShowUpdateDueDateNotificationModal(true)
      return
    }

    if (isWebFormContent && form.state.mode === "add") {
      // if questions are not configured, prompt the admin to setup questions
      if (
        !form.changedState.content?.webFormRevision?.questions?.length &&
        !webFormQuestionsConfirmationModal.isOpen
      )
        return webFormQuestionsConfirmationModal.onOpen()

      // warn the admin about question editing restrictions
      if (!createWebFormConfirmationModal.isOpen)
        return createWebFormConfirmationModal.onOpen()
    }

    try {
      const isCreation = form.state.mode === "add"
      const isDetaching = form.state.detachFromContent

      const { didSave } = await form.submit(
        {
          contentUsageId,
          organizationId: form.state.organizationId,
          productId: form.state.productId,
          contentId: form.state.contentId,
          contentType: form.state.contentType,
          contentSystemTaskKind: form.state.contentSystemTaskKind,
          content: {
            ...form.state.content,
            name: form.state.content.name,
            webFormRevision:
              form.state.content.webFormRevision &&
              // only submit a revision if there are changes, but submit all revision data (required by validators)
              (form.state.mode === "add" || form.changedState.content?.webFormRevision)
                ? {
                    ...form.state.content.webFormRevision,
                    questions: cleanWebFormQuestionsInput(
                      form.state.content.webFormRevision.questions
                    ),
                  }
                : undefined,
          },
          contentUsageInput: {
            ...form.state.contentUsageInput,
            // Since for existing items we can update the entityId and ordering elsewhere such
            // as InlineCollectionItemModuleForm or curriculum drag and drop we only want
            // to pass these here on creation as they could be stale values
            entity:
              form.state.mode === "add"
                ? form.state.contentUsageInput?.entity
                : undefined,
            entityId:
              form.state.mode === "add"
                ? form.state.contentUsageInput?.entityId
                : undefined,
            ordering:
              form.state.mode === "add"
                ? form.state.contentUsageInput?.ordering
                : undefined,
          },
          detachFromContent: isDetaching,
          botResponseIds: brIds ? JSON.parse(decodeURIComponent(brIds)) : undefined,
        },
        {
          variables: {
            contentLabelIds: filterContentLabelId ? [filterContentLabelId] : undefined,
          },
          updater: (store, payload) => {
            const usageId = payload?.response?.node?.id
            if (!usageId) return

            // If creating content in a collection
            if (isCreation && isCollection) {
              ContentModuleUtils.insertUsageIntoModuleGridConnection({
                store,
                moduleId: form.state.contentUsageInput!.entityId!,
                contentUsageId: usageId,
                ordering: form.state.contentUsageInput?.ordering,
              })
            }
          },
        }
      )

      if (createWebFormConfirmationModal.isOpen) createWebFormConfirmationModal.onClose()
      if (!didSave) return

      displaySuccessToast({
        message: `${contentLabel} ${form.state.mode === "add" ? "created" : "updated"}!`,
        testid: `${testid}.success-toast`,
      })

      if (form.state.mode === "add") {
        form.state.mode = "edit"
        // Manually reset form state to avoid the form being marked as changed after creation
        form.resetInitialState()

        // Load the new content usage into the cache
        refetch({ id: contentUsageId }, { inBackground: true })

        // invalidate all collection folder connections if adding content
        commitLocalUpdate(RelayEnvironment, (store) => {
          ContentModuleUtils.invalidateCollectionFolderConnections(
            store,
            form.state.contentUsageInput?.entity,
            form.state.contentUsageInput?.entityId
          )
        })
      }

      drawer.setParams({ brIds: undefined })
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function handleClose() {
    handleLeave({
      onLeave: () => {
        stopGenerating()
        drawer.close()
      },
    })
  }
}

export function InlineContentDrawerTemplateSkeleton() {
  const permission = useActiveProductOrOrganizationPermissions()
  const { isCurriculum } = useContentUsageDrawerContext()

  const canManage = permission.has("content.manage")

  const showFooter = Boolean(isCurriculum)

  const classes = useStyles({
    hasSidebarContent: isCurriculum,
    showFooter,
    canManage,
    isSidebarCollapsed: true,
  })
  const theme = useTheme()
  const drawer = useContentUsageDrawer()

  return (
    <div className={classes.container}>
      <DiscoDrawerHeader
        className={classes.drawerHeader}
        classes={{ header: classes.drawerHeaderContent }}
        title={
          <div>
            <DiscoTextSkeleton variant={"body-xs-600-uppercase"} width={100} />
            <DiscoTextSkeleton variant={"heading-xl"} width={250} />
          </div>
        }
        onClose={drawer.close}
      />
      <ScrollShadowContainer>
        <div className={classes.contentContainer}>
          <Skeleton
            variant={"rect"}
            style={{
              width: "100%",
              height: "260px",
              borderRadius: theme.measure.borderRadius.big,
            }}
          />
          <Skeleton
            variant={"rect"}
            style={{
              width: "100%",
              height: "160px",
              borderRadius: theme.measure.borderRadius.big,
            }}
          />
        </div>
      </ScrollShadowContainer>
      {showFooter && <InlineContentDrawerFooter />}
    </div>
  )
}

type StyleProps = {
  hasSidebarContent: boolean
  showFooter: boolean
  canManage: boolean
  isSidebarCollapsed: boolean
}

const useStyles = makeUseStyles((theme) => ({
  drawerHeader: {
    padding: theme.spacing(3, 3, 0, 3),

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 3, 0, 1.5),
    },
  },
  drawerHeaderContent: ({ canManage }: StyleProps) => ({
    // Set a static height to prevent differences in height between skeleton
    // and content that may cause a content shift on load
    height: "68px",
    ...styleIf(canManage, { height: "86px" }),
  }),
  container: ({ hasSidebarContent }: StyleProps) => ({
    height: "100%",
    width: "100%",

    ...styleIf(hasSidebarContent, {
      display: "grid",
      position: "relative",
    }),

    [theme.breakpoints.down("sm")]: {
      // Make this container static on mobile so that the absolutely positioned options container
      // will not use this as a position and instead be fixed to the bottom of the screen
      position: "static",
    },
  }),
  content: ({ showFooter, hasSidebarContent }: StyleProps) => ({
    height: "100%",

    ...styleIf(hasSidebarContent, {
      overflow: "hidden",
    }),
    ...styleIf(showFooter, {
      paddingBottom: CONTENT_DRAWER_FOOTER_HEIGHT,
    }),
  }),
  // Separated from content class as breakpoints and styleIf do not work well together
  multipleUsagesContent: {
    [theme.breakpoints.down("md")]: {
      paddingBottom: CONTENT_DRAWER_FOOTER_USAGES_MD_HEIGHT,
    },
    [theme.breakpoints.down("xs")]: {
      height: CONTENT_DRAWER_FOOTER_USAGES_XS_HEIGHT,
    },
  },
  richEditor: {
    paddingBottom: theme.spacing(2),
    // Ensure the rich editor toolbox is above the comment section but below the label dropdown
    zIndex: theme.zIndex.raise2,
  },
  contentContainer: ({ isSidebarCollapsed }: StyleProps) => ({
    height: "100%",
    width: "100%",
    display: "grid",
    gap: theme.spacing(2.5),
    alignContent: "start",
    maxWidth: "1140px",
    margin: "0 auto",
    padding: isSidebarCollapsed ? theme.spacing(3, 8, 6) : theme.spacing(3, 3, 6),

    [theme.breakpoints.down("lg")]: {
      padding: isSidebarCollapsed ? theme.spacing(3, 8, 6) : theme.spacing(3, 4, 6),
    },

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.5),
    },
  }),
  contentFooterSection: {
    paddingBottom: theme.spacing(2),
  },
  bookmarkButton: {
    marginRight: theme.spacing(1),
    width: "48px",
    height: "48px",
  },
}))

export default Relay.withSkeleton({
  component: observer(InlineContentDrawerTemplate),
  skeleton: InlineContentDrawerTemplateSkeleton,
})
